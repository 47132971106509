<template>
  <div>
    <div class="wrapper" v-if="!isContrast && !isAdd">
      <div class="content">
        <div class="card">
          <el-page-header @back="goBack" :content="info.name"> </el-page-header>
        </div>
        <div
          style="
            display: flex;
            padding: 11px 0 11px 21px;
            border: 1px solid #e8e8e8;
            border-top: none;
            border-radius: 0 0 7px 7px;
          "
        >
          <div class="left">
            <!-- <el-button type="primary">打印统计</el-button> -->
            <el-button
              type="primary"
              @click="
                () => {
                  isAdd = true;
                }
              "
              >编辑统计</el-button
            >
          </div>
          <div class="line"></div>
          <div class="middle">
            <el-form :model="cond" class="middle" label-width="80px">
              <el-form-item label="统计门店">
                <el-select
                  v-model="cond.hierarchy_id"
                  placeholder="请选择统计门店"
                  @change="handleAllSearch"
                >
                  <template v-for="(item, index) in storeList">
                    <el-option
                      :label="item.name"
                      :value="String(item.hierarchy_id)"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="销售时间">
                <el-date-picker
                  v-model="cond.day_time"
                  type="daterange"
                  value-format="timestamp"
                  placeholder="选择年"
                  @change="handleAllSearch"
                >
                </el-date-picker>
                <!-- <el-date-picker
                  v-if="time_type === 'month'"
                  v-model="cond.day_time"
                  type="month"
                  value-format="timestamp"
                  placeholder="选择月"
                  @change="handleAllSearch"
                >
                </el-date-picker>
                <el-date-picker
                  v-if="time_type === 'day'"
                  v-model="cond.day_time"
                  type="date"
                  placeholder="选择天"
                  value-format="timestamp"
                  @change="handleAllSearch"
                >
                </el-date-picker> -->
              </el-form-item>
            </el-form>
          </div>
          <div class="left" style="margin-left: 20px">
            <el-button
              type="primary"
              @click="
                () => {
                  contrastVisiable = true;
                }
              "
              >数据对比</el-button
            >
          </div>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div class="condition">
          统计条件<span class="condition_box"
            >统计类型：{{ time_typeAll[time_type] }}统计</span
          >
        </div>
        <div class="cline"></div>
        <div class="moneyContent">
          <div
            class="left"
            v-if="goodsInfoList.find((item) => item.text === '实售金额')"
          >
            <div class="moneyTitle">
              <div class="span"></div>
              实收金额
            </div>
            <div style="display: flex">
              <div class="moneyBox">
                <div class="num">
                  {{ sale_price }}
                </div>
                <div class="text">标签价</div>
              </div>
              <div class="operation">-</div>
              <div class="moneyBox">
                <div class="num">
                  {{ discount_price }}
                </div>
                <div class="text">优惠金额</div>
              </div>
              <div class="operation">-</div>
              <div class="moneyBox">
                <div class="num">
                  {{ recovery_price }}
                </div>
                <div class="text">回收价</div>
              </div>
              <!-- <div class="operation">-</div> -->
              <!-- <div class="moneyBox">
                <div class="num">450</div>
                <div class="text">以旧换新金额</div>
              </div> -->
              <div class="operation">=</div>
              <div class="moneyBox">
                <div class="num">
                  {{
                    parseFloat(
                      parseFloat(
                        sale_price - discount_price - recovery_price
                      ).toFixed(4)
                    )
                  }}
                </div>
                <div class="text">实收金额</div>
              </div>
            </div>
          </div>
          <div
            style="margin-left: 60px"
            v-if="goodsInfoList.find((item) => item.text === '成本价')"
          >
            <div class="moneyTitle">
              <div class="span"></div>
              利润金额
            </div>
            <div style="display: flex">
              <div class="moneyBox">
                <div class="num">
                  {{ actual_sale_price }}
                </div>
                <div class="text">实售金额</div>
              </div>
              <div class="operation">-</div>
              <div class="moneyBox">
                <div class="num">
                  {{ cost_price }}
                </div>
                <div class="text">成本价</div>
              </div>
              <div class="operation">=</div>
              <div class="moneyBox">
                <div class="num">
                  {{
                    parseFloat(
                      parseFloat(actual_sale_price - cost_price).toFixed(4)
                    )
                  }}
                </div>
                <div class="text">利润金额</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content"
        style="
          display: flex;
          border: 1px solid #e8e8e8;
          border-radius: 7px;
          margin-top: 10px;
          padding-bottom: 15px;
        "
      >
        <div style="width: 50%">
          <div class="trabsTitle">
            <div class="trabsSpan"></div>
            收款渠道统计
          </div>
          <div style="display: flex">
            <div id="payType" style="width: 40%; margin-left: 15px"></div>
            <div
              class="table"
              style="margin-left: 15px; border: 1px solid #e8e8e8"
            >
              <el-table
                :data="payTypeList"
                stripe=""
                max-height="300"
                :summary-method="getSummaries_Other"
                show-summary
              >
                <el-table-column
                  prop="item"
                  label="收款渠道"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="score"
                  label="收款金额(元)"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    {{ scope.row["score"] + "元" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="scale"
                  label="金额占比"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    {{
                      parseFloat(parseFloat(scope.row.scale).toFixed(2)) + "%"
                    }}
                  </template></el-table-column
                >
              </el-table>
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <div class="trabsTitle">
            <div class="trabsSpan"></div>
            优惠金额统计
          </div>
          <div style="display: flex">
            <div id="discountPirce" style="width: 40%; margin-left: 15px"></div>
            <div
              class="table"
              style="margin-left: 15px; border: 1px solid #e8e8e8"
            >
              <el-table
                :data="disCountPirceList"
                stripe
                max-height="300"
                :summary-method="getSummaries_Other"
                show-summary
              >
                <el-table-column
                  prop="item"
                  label="优惠类型"
                  align="center"
                  width="180"
                ></el-table-column>
                <el-table-column
                  prop="score"
                  label="优惠金额(元)"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    {{ scope.row["score"] + "元" }}
                  </template></el-table-column
                >
                <el-table-column
                  prop="scale"
                  label="金额占比"
                  align="center"
                  width="150"
                >
                  <template slot-scope="scope">
                    {{
                      parseFloat(parseFloat(scope.row.scale).toFixed(2)) + "%"
                    }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div class="trabsTitle">
          <div class="trabsSpan"></div>
          货品维度统计
        </div>

        <div class="conditionData">
          <template v-for="(item, index) in goodsInfoList">
            <div class="condBox" style="margin-right: 10px" :key="index">
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div style="padding-left: 18px">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 15px"
            @change="handleDimensionType"
          >
            <template v-for="(item, index) in dimension">
              <el-radio-button
                :label="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
                >{{ item.attr_name }}</el-radio-button
              >
            </template>
          </el-radio-group>
        </div>
        <div class="tab">
          <el-tabs
            v-model="statisticalAttr_id"
            style="height: 200px; width: 90%"
            @tab-click="handleStatisticType"
          >
            <template v-for="(item, index) in statistical_header">
              <el-tab-pane
                :label="item.attr_name"
                :name="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </div>

        <div class="containData" slot="margin-bottom: 15px;margin-top: 30px">
          <div style="position: absolute; top: 10px; right: 10px">
            <el-form :model="form" style="width: 200px">
              <el-select
                placeholder="请选择统计类型"
                v-model="form.time_type"
                @change="handleDayTime(1)"
                style="width: 80%"
              >
                <el-option value="year" label="按月"></el-option>
                <el-option value="month" label="按天"></el-option>
                <el-option value="day" label="按小时"></el-option>
                <!-- <el-option value="40" label="自定义时间"></el-option> -->
              </el-select>
            </el-form>
          </div>
          <div style="display: flex; margin-top: 50px">
            <div id="container"></div>
            <div id="line" style="margin-left: 80px"></div>
          </div>
        </div>
        <div class="table" style="margin: 15px; border-top: 1px solid #e8e8e8">
          <el-table
            :data="statisticalData"
            stripe
            max-height="500"
            :summary-method="getSummaries"
            show-summary
          >
            <template v-for="(item, index) in statisticalRowList">
              <el-table-column
                :key="index"
                align="center"
                :prop="item.header_id"
                :label="item.name"
              ></el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div class="trabsTitle">
          <div class="trabsSpan"></div>
          旧料维度统计
        </div>
        <div class="conditionData">
          <template v-for="(item, index) in goodsInfoList_old">
            <div class="condBox" style="margin-right: 10px" :key="index">
              <div class="num">
                {{ parseFloat(parseFloat(item.value).toFixed(4)) }}
              </div>
              <div class="text">{{ item.text }}</div>
            </div>
          </template>
        </div>
        <div style="padding-left: 18px">
          <el-radio-group
            v-model="tabPosition_old"
            style="margin-bottom: 15px"
            @change="handleDimensionType_old"
          >
            <template v-for="(item, index) in dimension_old">
              <el-radio-button
                :label="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
                >{{ item.attr_name }}</el-radio-button
              >
            </template>
          </el-radio-group>
        </div>
        <div class="tab">
          <el-tabs
            v-model="statisticalAttr_id_old"
            style="height: 200px; width: 90%"
            @tab-click="handleStatisticType_old"
          >
            <template v-for="(item, index) in statistical_header_old">
              <el-tab-pane
                :label="item.attr_name"
                :name="String(item.attr_id)"
                :key="index"
                v-if="item.is_set === 1"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </div>

        <div class="containData" slot="margin-bottom: 15px;margin-top: 30px">
          <div style="position: absolute; top: 10px; right: 10px">
            <el-form :model="old_form" style="width: 200px">
              <el-select
                placeholder="请选择统计类型"
                v-model="old_form.time_type"
                @change="handleDayTime(2)"
                style="width: 80%"
              >
                <el-option value="year" label="按月"></el-option>
                <el-option value="month" label="按天"></el-option>
                <el-option value="day" label="按小时"></el-option>
                <!-- <el-option value="40" label="自定义时间"></el-option> -->
              </el-select>
            </el-form>
          </div>
          <div style="display: flex; margin-top: 50px">
            <div id="container_old"></div>
            <div id="line_old" style="margin-left: 80px"></div>
          </div>
        </div>
        <div class="table" style="margin: 15px; border-top: 1px solid #e8e8e8">
          <el-table
            :data="statisticalData_old"
            stripe
            max-height="500"
            :summary-method="getSummaries"
            show-summary
          >
            <template v-for="(item, index) in statisticalRowList_old">
              <el-table-column
                :key="index"
                align="center"
                :prop="item.header_id"
                :label="item.name"
              ></el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div
        class="content"
        style="border: 1px solid #e8e8e8; border-radius: 7px; margin-top: 10px"
      >
        <div class="trabsTitle">
          <div class="trabsSpan"></div>
          销售明细
          <el-button
            type="primary"
            style="margin: 5px 18px 0 15px; height: 40px; line-heigth: 40px"
            @click="handleExport"
            >导出</el-button
          >
        </div>
        <div class="tab">
          <div
            class="tabBox"
            :style="detaildedType === '' ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType('')"
          >
            全部
            <div class="label" v-if="detaildedType === ''"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 10 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(10)"
          >
            销售
            <div class="label" v-if="detaildedType === 10"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 20 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(20)"
          >
            以旧换新
            <div class="label" v-if="detaildedType === 20"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 50 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(50)"
          >
            纯回收
            <div class="label" v-if="detaildedType === 50"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 30 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(30)"
          >
            退货
            <div class="label" v-if="detaildedType === 30"></div>
          </div>
          <div
            class="tabBox"
            :style="detaildedType === 40 ? 'color: #2791ff;' : ''"
            @click="handleDetailGoodsType(40)"
          >
            赎回
            <div class="label" v-if="detaildedType === 40"></div>
          </div>
        </div>
        <div
          class="card"
          style="
            margin: 0px 18px 0 18px;
            border-radius: 5px 5px 0 0;
            border-top: 1px solid #e8e8e8;
          "
        >
          <el-form :model="detaildedForm">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="品类"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <SelectTree
                    id="select"
                    :props="props"
                    :options="goodsTypeList"
                    label1="商品分类"
                    :clearable="isClearable"
                    :accordion="isAccordion"
                    @getValue="getValue($event)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="供应商"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm.supplier_id"
                    placeholder="请选择供应商"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in supplierList">
                      <el-option
                        :label="item.supplier_name"
                        :value="item.supplier_id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="仓库"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm.warehouse_id"
                    placeholder="请选择仓库"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in wareHouseList">
                      <el-option
                        :label="item.warehouse_name"
                        :value="item.warehouse_id"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="款式"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[goodsStyleKey]"
                    placeholder="请选择款式"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in goodsStyleList">
                      <el-option
                        :label="item.value"
                        :value="item.value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="品牌"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[goodsBrandKey]"
                    placeholder="请选择品牌"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in goodsBrandList">
                      <el-option
                        :label="item.value"
                        :value="item.value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="材质成色"
                  label-width="100px"
                  style="margin-bottom: 15px !important"
                >
                  <el-select
                    v-model="detaildedForm[materialKey]"
                    placeholder="请选择材质成色"
                    filterable
                    clearable
                    @change="getTotalStatisticalList"
                  >
                    <template v-for="(item, index) in materialList">
                      <el-option
                        :label="item.attr_value"
                        :value="item.attr_value"
                        :key="index"
                      ></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div
          class="table"
          style="margin: 0 18px 18px 18px; border-radius: 0 0 5px 5px"
        >
          <el-table
            :data="detailedList"
            stripe
            max-height="700"
            v-loading="tableLoading"
            :summary-method="getNowSummaries"
            show-summary
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="80"
            ></el-table-column>
            <template v-for="(item, index) in detailedHead">
              <el-table-column
                :key="index"
                :prop="item.key"
                :label="item.name"
                align="center"
                width="220"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row[item.key] || "--" }}</div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
        <div style="padding: 15px 0; display: flex; justify-content: flex-end">
          <el-pagination
            :current-page="detaildedForm.page"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="detaildedTotal"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>

      <el-dialog title="数据对比" :visible.sync="contrastVisiable" width="30%">
        <div>
          <el-form
            :model="contrastCond"
            style="margin: 0 auto"
            label-width="80px"
            :rules="rules"
            ref="contrastCond"
          >
            <el-form-item
              label="统计门店"
              style="margin-bottom: 20px !important"
              prop="hierarchy_id"
            >
              <el-select
                v-model="contrastCond.hierarchy_id"
                placeholder="请选择统计门店"
              >
                <template v-for="(item, index) in storeList">
                  <el-option
                    :label="item.name"
                    :value="String(item.hierarchy_id)"
                    :key="index"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="销售时间" prop="day_time">
              <el-date-picker
                v-model="contrastCond.day_time"
                type="daterange"
                value-format="timestamp"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>

              <!-- <el-date-picker
                v-if="time_type === 'month'"
                v-model="contrastCond.day_time"
                type="month"
                placeholder="选择月"
                value-format="timestamp"
              >
              </el-date-picker>
              <el-date-picker
                v-if="time_type === 'day'"
                v-model="contrastCond.day_time"
                type="date"
                placeholder="选择天"
                value-format="timestamp"
              >
              </el-date-picker> -->
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin-top: 20px">
            <el-button @click="() => (contrastVisiable = false)"
              >取消</el-button
            >
            <el-button type="primary" @click="handleToContrast('contrastCond')"
              >确定</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="提示：导出数据过大，需要分批导出"
        :visible.sync="exportVisiable"
        width="25%"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :before-close="cancelExport"
      >
        <div
          v-for="(item, index) in exportList"
          :key="index"
          style="
            margin: 0 auto 10px auto;
            display: flex;
            width: 330px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border: 1px solid #e8e8ee;
            border-radius: 5px;
          "
        >
          <div style="width: 200px; text-align: center">第{{ item.text }}</div>
          <div>
            <el-button
              size="mini"
              type="primary"
              style="
                width: 80px;
                height: 30px;
                margin-left: 15px;
                margin-top: 5px;
                margin-bottom: 5px;
              "
              @click.stop="handleExportDetail(2, item, index)"
              :disabled="exportDisable"
              :loading="exportIndex === index + 1 ? exportLoading : false"
              >下载</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <Contrast
      v-if="isContrast"
      :contrastCond="contrastCond"
      @hide="goBackDetails"
      :sales_statistical_id="sales_statistical_id"
      :cond="cond"
    />
    <Add
      v-if="isAdd"
      @hide="handleCloseAdd"
      :sales_statistical_id="sales_statistical_id"
    />
  </div>
</template>
<script>
import {
  getTotalStatisticalData,
  getTotalStatisticalRadar,
  getTotalStatisticalTime,
  getStatistical,
  getTotalStatisticalList,
  getAttrValueList,
  downloadList,
  getMaterialListReq,
  totalStatisticalPay,
  totalStatisticalDiscount,
} from "@/api/statistics/saleOrder/index.js";

import { getWarehouseListAll } from "@/api/statistics/transfer/index.js";
import { getListReq as getTypeListReq } from "@/api/goods/goodsConfig/classify";
import { getSupplierList } from "@/api/goods/goodsWarehousing/warehousing";
import { getHierarchyAll } from "@/api/index";
import { Line, Pie, measureTextWidth } from "@antv/g2plot";
import storage from "good-storage";
import Add from "./Add.vue";
import Contrast from "./sComparison.vue";
import Analysis from "./analysis.vue";

export default {
  data() {
    return {
      userInfo: {},
      info: {},
      cond: {
        day_time: [],
        hierarchy_id: 0,
      },
      tabPosition: "",
      tabPosition_old: "",
      radarData: [],
      lineData: [],
      tableData: [],
      radarData_old: [],
      lineData_old: [],
      dimension: [],
      list_header: [],
      statistical_header: [],
      statisticalData: [],
      statisticalRowList: [],
      attr_id: null,
      statisticalAttr_id: "",
      radarPlot: null,
      line: null,
      time_typeAll: {
        month: "按月",
        day: "按天",
        year: "按年",
      },
      goodsTypeList: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）

      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      supplierList: [],
      goodsStyleList: [],
      goodsStyleKey: 0,
      goodsBrandList: [],
      goodsBrandKey: 0,
      time_type: "",
      wareHouseList: [],
      detailedHead: [],
      detailedList: [],
      detailedLastList: [],
      detaildedForm: {
        page: 1,
        page_size: 50,
      },
      detaildedTotal: 0,
      detaildedType: "",
      form: { time_type: "" },
      old_form: { time_type: "" },
      materialList: [],
      materialKey: 0,
      storeList: [],
      contrastVisiable: false,
      contrastCond: {},
      isContrast: false,
      isAdd: false,
      goodsInfoList: [],
      goodsInfoList_old: [],
      tableLoading: false,
      exportVisiable: false,
      exportList: [],
      exportDisable: false,
      exportLoading: false,
      exportIndex: 1,
      dimension_old: [],
      statisticalData_old: [],
      statistical_header_old: [],
      statisticalRowList_old: [],
      statisticalAttr_id_old: "",
      line_old: null,
      radarPlot_old: null,
      attr_id_old: null,
      rules: {
        hierarchy_id: [
          {
            required: true,
            message: "请选择对比门店",
            trigger: "change",
          },
        ],
        day_time: [
          {
            required: true,
            message: "请选择销售时间",
            trigger: "change",
          },
        ],
      },
      sale_price: 0, // 标签价
      actual_sale_price: 0, // 实收金额
      discount_price: 0, //优惠金额
      cost_price: 0, // 成本价
      profit_price: 0, // 利润
      recovery_price: 0, // 回收价
      discountPricePlot: null,
      disCountPirceList: [],
      payTypePlot: null,
      payTypeList: [],
    };
  },
  props: {
    sales_statistical_id: Number,
  },
  components: {
    Contrast,
    Add,
    Analysis,
  },
  created() {
    this.userInfo = storage.get("userInfo");
    this.getTypeList();
    this.getSupplierList();
    this.getMerchants();
  },
  mounted() {
    this.getStatistical();
    this.getStatistical_old();
  },
  methods: {
    handleCloseAdd() {
      this.isAdd = false;
      this.getStatistical();
      this.getStatistical_old();
    },
    handleAllSearch() {
      this.getTotalStatisticalList();
      this.getTotalStatisticalData();
      this.getTotalStatisticalData_old();
      this.getWarehouseList();
    },
    getSummaries_Other(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        let values = data.map((item) => Number(item[column.property]));
        console.log(column.property);
        if (column.property === "scale") {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index] + "%";
          } else {
            sums[index] = "N/A";
          }
        } else {
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index] + "元";
          } else {
            sums[index] = "N/A";
          }
        }
      });

      return sums;
    },

    /*
     *  获取门店数据
     */
    getMerchants() {
      getHierarchyAll({
        hierarchy_id: storage.get("userInfo").hierarchy_ids,
      }).then((res) => {
        if (res.code === 1) {
          if (this.userInfo.hierarchy_type_id === 30) {
            res.data.unshift({ hierarchy_id: 0, name: "全部门店" });
          }
          this.storeList = res.data;
        }
      });
    },
    handleToContrast(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.contrastVisiable = false;
          this.isContrast = true;
          this.storeList.forEach((item) => {
            if (item.hierarchy_id === Number(this.contrastCond.hierarchy_id)) {
              this.contrastCond.hierarchy_name = item.name;
              this.contrastCond.hierarchy_logo = item.logo;
            }
            if (item.hierarchy_id === Number(this.cond.hierarchy_id)) {
              this.cond.hierarchy_logo = item.logo;
              this.cond.hierarchy_name = item.name;
            }
          });
          if (this.contrastCond.day_time) {
            this.contrastCond.day_time =
              this.contrastCond.day_time[0] / 1000 +
              "," +
              this.contrastCond.day_time[1] / 1000;
          }
        }
      });
    },
    /*
     *  获取品类下拉数据
     */
    getTypeList() {
      const data = {};
      if (this.detaildedType !== "") {
        let type = this.detaildedType;
        if (type === 30) {
          type = 10;
        }
        if (type === 40) {
          type = 20;
        }
        if (type === 50) {
          type = 20;
        }
        data.type = type;
      }
      getTypeListReq(data).then((res) => {
        if (res.code === 1) {
          this.goodsTypeList = res.data;
        }
      });
    },
    /*
     ** 品类下拉取值
     */
    getValue(value) {
      const form = { ...this.detaildedForm };
      form.goods_type_id = value;
      this.detaildedForm = form;
      this.$nextTick(() => {
        const dom = document
          .getElementById("select")
          .getElementsByTagName("input")[0];
        this.text = dom.value;
      });
      this.getTotalStatisticalList();
    },
    /*
     *   获取供应商下拉数据
     */
    getSupplierList() {
      getSupplierList().then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data;
        }
      });
    },
    goBackDetails() {
      this.isContrast = false;
      this.contrastCond = {};
      this.getStatistical();
      this.getStatistical_old();
    },
    /*
     *  获取款式下拉
     */
    getGoodsStyle() {
      this.goodsStyleKey = this.dimension.find(
        (item) => item.system_attr_id === 101
      ).attr_id;
      const data = {
        attr_id: this.dimension.find((item) => item.system_attr_id === 101)
          .attr_id,
        h_id: this.userInfo.hierarchy_id,
      };
      getAttrValueList(data).then((res) => {
        if (res.code === 1) {
          this.goodsStyleList = res.data;
        }
      });
    },
    /*
     *  获取品牌下拉
     */
    getGoodsBrand() {
      this.goodsBrandKey = this.list_header.find(
        (item) => item.system_attr_id === 103
      ).attr_id;
      const data = {
        attr_id: this.list_header.find((item) => item.system_attr_id === 103)
          .attr_id,
        h_id: this.userInfo.hierarchy_id,
      };
      getAttrValueList(data).then((res) => {
        if (res.code === 1) {
          this.goodsBrandList = res.data;
        }
      });
    },
    /*
     *  获取仓库下拉数据
     */
    getWarehouseList() {
      const data = {};
      if (this.cond.hierarchy_id !== "0") {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getWarehouseListAll(data).then((res) => {
        if (res.code === 1) {
          res.data = res.data.map((item) => {
            item.warehouse_name = item.warehouse_name + "（" + item.name + "）";
            return item;
          });
          this.wareHouseList = res.data;
        }
      });
    },
    /*
     *  获取材质成色下拉数据
     */
    getMaterialColorList() {
      this.materialKey = this.list_header.find(
        (item) => item.system_attr_id === 100
      ).attr_id;
      getMaterialListReq().then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    /*
     *  获取销售统计默认或设置项_货品
     */
    getStatistical() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
      };
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      getStatistical(data).then((res) => {
        if (res.code === 1) {
          this.info = res.data.statistical;
          this.time_type = res.data.statistical.conditions.time_type;
          this.dimension = res.data.dimension;
          this.list_header = res.data.list_header;
          this.statistical_header = res.data.statistical_header;
          this.attr_id = res.data.dimension.filter(
            (item) => item.is_set === 1
          )[0].attr_id;
          this.tabPosition = String(
            res.data.dimension.filter((item) => item.is_set === 1)[0].attr_id
          );
          this.statisticalAttr_id = String(
            res.data.statistical_header.filter((item) => item.is_set === 1)[0]
              .attr_id
          );
          this.cond = {
            day_time: [
              res.data.day_time.split(",")[0] * 1000,
              res.data.day_time.split(",")[1] * 1000,
            ],

            hierarchy_id: String(res.data.hierarchy_id),
          };
          this.form = {
            time_type: res.data.statistical.conditions.time_type,
          };

          this.getTotalStatisticalData();
          this.getGoodsStyle();
          this.getTotalStatisticalList();
          this.getGoodsBrand();
          this.getMaterialColorList();
          this.getWarehouseList();
        }
      });
    },
    /*
     *  获取销售统计默认或设置项_货品
     */
    getStatistical_old() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 20,
      };
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      getStatistical(data).then((res) => {
        if (res.code === 1) {
          // 旧料
          this.dimension_old = res.data.dimension;
          this.statistical_header_old = res.data.statistical_header;
          this.attr_id_old = res.data.dimension.filter(
            (item) => item.is_set === 1
          )[0].attr_id;
          this.tabPosition_old = String(
            res.data.dimension.filter((item) => item.is_set === 1)[0].attr_id
          );
          this.statisticalAttr_id_old = String(
            res.data.statistical_header.filter((item) => item.is_set === 1)[0]
              .attr_id
          );
          this.old_form = {
            time_type: res.data.statistical.conditions.time_type,
          };
          this.getTotalStatisticalData_old();
        }
      });
    },
    handleTotalStatisticalPay() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
      };
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      totalStatisticalPay(data).then((res) => {
        if (res.data.length !== 0) {
          if (this.payTypeList.length !== 0) {
            this.payTypePlot.destroy();
          }
          let sum = 0;
          res.data.forEach((item) => {
            sum += item.score;
          });
          res.data = res.data.map((item) => {
            if (sum > 0) {
              item.scale = parseFloat(item.score / sum).toFixed(2) * 100;
            } else {
              item.scale = 0;
            }
            return item;
          });
          this.payTypeList = res.data;
          this.handlePayType();
        } else if (res.data.length === 0) {
          this.payTypeList = [];
          if (this.payTypePlot) {
            this.payTypePlot.destroy();
          }
        }
      });
    },
    handleTotalStatisticalDiscount() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
      };
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      totalStatisticalDiscount(data).then((res) => {
        if (res.data.length !== 0) {
          if (this.disCountPirceList.length !== 0) {
            this.discountPricePlot.destroy();
          }
          let sum = 0;
          res.data.forEach((item) => {
            sum += item.score;
          });
          res.data = res.data.map((item) => {
            if (sum > 0) {
              item.scale = parseFloat(item.score / sum).toFixed(2) * 100;
            } else {
              item.scale = 0;
            }
            return item;
          });
          this.disCountPirceList = res.data;
          this.handleDiscountPirce();
        } else if (res.data.length === 0) {
          this.disCountPirceList = [];
          if (this.discountPricePlot) {
            this.discountPricePlot.destroy();
          }
        }
      });
    },
    handleDimensionType(tab) {
      this.attr_id = tab;
      this.getTotalStatisticalData();
    },
    handleDimensionType_old(tab) {
      this.attr_id_old = tab;
      this.getTotalStatisticalData_old();
    },
    /*
     *   合计
     */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = parseFloat(parseFloat(sums[index]).toFixed(4));
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
    /*
     *   当前页合计
     */
    getNowSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      if (this.detailedLastList.length !== 0) {
        columns.forEach((column, index) => {
          this.detailedLastList.forEach((item) => {
            sums[index] = item[column.property] || "--";
          });
        });
      }
      return sums;
    },
    /*
     * 销售统计-获取统计维度数据
     */
    getTotalStatisticalData() {
      this.sale_price = 0; // 标签价
      this.actual_sale_price = 0; // 实收金额
      this.discount_price = 0; //优惠金额
      this.cost_price = 0; // 成本价
      this.profit_price = 0; // 利润
      this.goodsInfoList = [];
      this.statisticalData = [];
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
      };
      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getTotalStatisticalData(data).then((res) => {
        if (res.code === 1) {
          if (res.data.list && res.data.list.length !== 0) {
            this.statisticalData = res.data.list;
            res.data.header.forEach((item, index) => {
              if (index !== 0) {
                const info = {
                  field: item.header_id,
                  text: item.name,
                  value: 0,
                };
                res.data.list.forEach((items) => {
                  Object.keys(items).forEach((i) => {
                    if (i === item.header_id) {
                      info.value += parseFloat(
                        parseFloat(items[item.header_id].toFixed(4))
                      );
                    }
                    if (
                      i === item.header_id &&
                      item.system_attr_id === "sale_price"
                    ) {
                      this.sale_price = parseFloat(
                        parseFloat(
                          this.sale_price + items[item.header_id]
                        ).toFixed(4)
                      );
                    }
                    if (
                      i === item.header_id &&
                      item.system_attr_id === "actual_sale_price"
                    ) {
                      this.actual_sale_price = parseFloat(
                        parseFloat(
                          this.actual_sale_price + items[item.header_id]
                        ).toFixed(4)
                      );
                    }
                    if (
                      i === item.header_id &&
                      item.system_attr_id === "discount_price"
                    ) {
                      this.discount_price = parseFloat(
                        parseFloat(
                          this.discount_price + items[item.header_id]
                        ).toFixed(4)
                      );
                    }
                    if (
                      i === item.header_id &&
                      item.system_attr_id === "cost_price"
                    ) {
                      this.cost_price = parseFloat(
                        parseFloat(
                          this.cost_price + items[item.header_id]
                        ).toFixed(4)
                      );
                    }
                  });
                });
                this.goodsInfoList.push({ ...info });
              }
            });
          }
          this.handleTotalStatisticalPay();
          this.handleTotalStatisticalDiscount();
          this.getTotalStatisticalTime();
          this.getTotalStatisticalRadar();
          this.statisticalRowList = res.data.header;
        }
      });
    },
    /*
     * 调拨统计-获取统计维度数据-旧料
     */
    getTotalStatisticalData_old() {
      this.recovery_price = 0;
      this.goodsInfoList_old = [];
      this.statisticalData_old = [];
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 20,
        dimension: this.attr_id_old,
      };
      if (this.old_form.time_type) {
        data.day_time = this.old_form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getTotalStatisticalData(data).then((res) => {
        if (res.code === 1) {
          if (res.data.list && res.data.list.length !== 0) {
            this.statisticalData_old = res.data.list;
            res.data.header.forEach((item, index) => {
              if (index !== 0) {
                const info = {
                  field: item.header_id,
                  text: item.name,
                  value: 0,
                };
                res.data.list.forEach((items) => {
                  Object.keys(items).forEach((i) => {
                    if (i === item.header_id) {
                      info.value += parseFloat(
                        parseFloat(items[item.header_id].toFixed(4))
                      );
                    }
                    if (
                      i === item.header_id &&
                      item.system_attr_id === "actual_sale_price"
                    ) {
                      this.recovery_price = parseFloat(
                        parseFloat(
                          this.recovery_price + items[item.header_id]
                        ).toFixed(4)
                      );
                    }
                  });
                });
                this.goodsInfoList_old.push({ ...info });
              }
            });
          }

          this.statisticalRowList_old = res.data.header;
          this.getTotalStatisticalTime_old();
          this.getTotalStatisticalRadar_old();
        }
      });
    },
    /*
     *  时间筛选
     */
    handleDayTime(type) {
      if (type === 1) {
        this.getTotalStatisticalTime();
      } else {
        this.getTotalStatisticalTime_old();
      }
    },
    /*
     *  时间轴
     */
    getTotalStatisticalTime() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
      };

      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }

      getTotalStatisticalTime(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.lineData.length !== 0) {
              this.line.destroy();
            }
            this.lineData = res.data;
            this.handleLine();
          } else if (res.data.length === 0) {
            this.lineData = [];
            if (this.line) {
              this.line.destroy();
            }
          }
        }
      });
    },
    /*
     *  时间轴_旧料
     */
    getTotalStatisticalTime_old() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 20,
        dimension: this.attr_id_old,
        statistical_type: this.statisticalAttr_id_old,
      };
      if (this.old_form.time_type) {
        data.time_type = this.old_form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getTotalStatisticalTime(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.lineData_old.length !== 0) {
              this.line_old.destroy();
            }
            this.lineData_old = res.data;
            this.handleLine_old();
          } else if (res.data.length === 0) {
            this.lineData_old = [];
            if (this.line_old) {
              this.line_old.destroy();
            }
          }
        }
      });
    },
    /*
     *  获取雷达图数据
     */
    getTotalStatisticalRadar() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 10,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
      };
      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getTotalStatisticalRadar(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.radarData.length !== 0) {
              this.radarPlot.destroy();
            }
            this.radarData = res.data;
            this.handleRadar();
          } else if (res.data.length === 0) {
            this.radarData = [];
            if (this.radarPlot) {
              this.radarPlot.destroy();
            }
          }
        }
      });
    },
    /*
     *  获取雷达图数据_旧料
     */
    getTotalStatisticalRadar_old() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: 20,
        dimension: this.attr_id,
        statistical_type: this.statisticalAttr_id,
      };
      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      getTotalStatisticalRadar(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            if (this.radarData_old.length !== 0) {
              this.radarPlot_old.destroy();
            }
            this.radarData_old = res.data;
            this.handleRadar_old();
          } else if (res.data.length === 0) {
            this.radarData_old = [];
            if (this.radarPlot_old) {
              this.radarPlot_old.destroy();
            }
          }
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },

    handleStatisticType(tab, event) {
      this.getTotalStatisticalTime();
      this.getTotalStatisticalRadar();
    },
    handleStatisticType_old(tab, event) {
      this.getTotalStatisticalTime_old();
      this.getTotalStatisticalRadar_old();
    },
    renderStatistic(containerWidth, text, style) {
      const { width: textWidth, height: textHeight } = measureTextWidth(
        text,
        style
      );
      const R = containerWidth / 2;
      let scale = 1;
      if (containerWidth < textWidth) {
        scale = Math.min(
          Math.sqrt(
            Math.abs(
              Math.pow(R, 2) /
                (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
            )
          ),
          1
        );
      }
      const textStyleStr = `width:${containerWidth}px;`;
      return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
        scale < 1 ? 1 : "inherit"
      };">${text}</div>`;
    },
    handlePayType() {
      this.payTypePlot = new Pie("payType", {
        appendPadding: 10,
        data: this.payTypeList,
        angleField: "score",
        colorField: "item",
        legend: false,
        width: 300,
        height: 300,
        radius: 1,
        innerRadius: 0.75,
        meta: {
          value: {
            formatter: (v) => `${v}`,
          },
        },
        label: {
          type: "inner",
          offset: "-50%",
          autoRotate: false,
          style: { textAlign: "center" },
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          rotate: true,
        },
        statistic: {
          title: {
            offsetY: -8,
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "26px",
            },
            customHtml: (container, view, datum, data) => {
              const { width } = container.getBoundingClientRect();
              const text = datum
                ? `${datum.score}`
                : `${data.reduce(
                    (r, d) => parseFloat(parseFloat(r + d.score).toFixed(4)),
                    0
                  )}`;
              return this.renderStatistic(width, text, { fontSize: 26 });
            },
          },
        },
      });
      this.payTypePlot.render();
    },
    handleDiscountPirce() {
      this.discountPricePlot = new Pie("discountPirce", {
        appendPadding: 10,
        data: this.disCountPirceList,
        angleField: "score",
        colorField: "item",
        radius: 1,
        innerRadius: 0.75,
        width: 300,
        height: 300,
        legend: false,
        meta: {
          value: {
            formatter: (v) => `${v}`,
          },
        },
        label: {
          type: "inner",
          offset: "-50%",
          autoRotate: false,
          style: { textAlign: "center" },
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        },
        statistic: {
          title: {
            offsetY: -8,
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "26px",
            },
            customHtml: (container, view, datum, data) => {
              const { width } = container.getBoundingClientRect();
              const text = datum
                ? `${datum.score}`
                : `${data.reduce(
                    (r, d) => parseFloat(parseFloat(r + d.score).toFixed(4)),
                    0
                  )}`;
              return this.renderStatistic(width, text, { fontSize: 26 });
            },
          },
        },
      });
      this.discountPricePlot.render();
    },
    handleRadar() {
      this.radarPlot = new Pie("container", {
        appendPadding: 10,
        data: this.radarData,
        angleField: "score",
        colorField: "item",
        radius: 1,
        innerRadius: 0.75,
        width: 400,
        height: 400,
        meta: {
          value: {
            formatter: (v) => `${v}`,
          },
        },
        label: {
          type: "inner",
          offset: "-50%",
          autoRotate: false,
          style: { textAlign: "center" },
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        },
        statistic: {
          title: {
            offsetY: -8,
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "26px",
            },
            customHtml: (container, view, datum, data) => {
              const { width } = container.getBoundingClientRect();
              const text = datum
                ? `${datum.score}`
                : `${data.reduce(
                    (r, d) => parseFloat(parseFloat(r + d.score).toFixed(4)),
                    0
                  )}`;
              return this.renderStatistic(width, text, { fontSize: 26 });
            },
          },
        },
      });
      this.radarPlot.render();
    },
    handleRadar_old() {
      this.radarPlot_old = new Pie("container_old", {
        appendPadding: 10,
        data: this.radarData_old,
        angleField: "score",
        colorField: "item",
        radius: 1,
        innerRadius: 0.75,
        width: 400,
        height: 400,
        meta: {
          value: {
            formatter: (v) => `${v}`,
          },
        },
        label: {
          type: "inner",
          offset: "-50%",
          autoRotate: false,
          style: { textAlign: "center" },
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        },
        statistic: {
          title: {
            offsetY: -8,
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "26px",
            },
            customHtml: (container, view, datum, data) => {
              const { width } = container.getBoundingClientRect();
              const text = datum
                ? `${datum.score}`
                : `${data.reduce(
                    (r, d) => parseFloat(parseFloat(r + d.score).toFixed(4)),
                    0
                  )}`;
              return this.renderStatistic(width, text, { fontSize: 26 });
            },
          },
        },
      });
      this.radarPlot_old.render();
    },
    handleLine() {
      this.line = new Line("line", {
        data: this.lineData,
        xField: "time",
        yField: "data",
        seriesField: "name",
        yAxis: {
          label: {
            formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
          },
        },
        legend: {
          position: "top",
        },
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        width: 1200,
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) =>
              `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
      });

      this.line.render();
    },
    handleLine_old() {
      this.line_old = new Line("line_old", {
        data: this.lineData_old,
        xField: "time",
        yField: "data",
        seriesField: "name",
        yAxis: {
          label: {
            formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
          },
        },
        legend: {
          position: "top",
        },
        smooth: true,
        animation: {
          appear: {
            animation: "path-in",
            duration: 5000,
          },
        },
        width: 1200,
        yAxis: {
          label: {
            // 数值格式化为千分位
            formatter: (v) =>
              `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
      });

      this.line_old.render();
    },
    /*
     *  明细
     */
    getTotalStatisticalList() {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: this.detaildedType || "",
        fixed: {},
        custom: {},
      };
      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }
      if (this.detaildedForm.page) {
        data.page = this.detaildedForm.page;
      }
      if (this.detaildedForm.page_size) {
        data.page_size = this.detaildedForm.page_size;
      }
      if (this.detaildedForm.goods_type_id) {
        data.fixed.goods_type_id = this.detaildedForm.goods_type_id;
      }
      if (this.detaildedForm.supplier_id) {
        data.fixed.supplier_id = this.detaildedForm.supplier_id;
      }
      if (this.detaildedForm.warehouse_id) {
        data.fixed.warehouse_id = this.detaildedForm.warehouse_id;
      }
      if (this.goodsStyleKey !== 0 && this.detaildedForm[this.goodsStyleKey]) {
        data.custom[this.goodsStyleKey] =
          this.detaildedForm[this.goodsStyleKey];
      }
      if (this.goodsBrandKey !== 0 && this.detaildedForm[this.goodsBrandKey]) {
        data.custom[this.goodsBrandKey] =
          this.detaildedForm[this.goodsBrandKey];
      }
      if (this.materialKey !== 0 && this.detaildedForm[this.materialKey]) {
        data.custom[this.materialKey] = this.detaildedForm[this.materialKey];
      }

      data.fixed = JSON.stringify(data.fixed);
      data.custom = JSON.stringify(data.custom);
      this.tableLoading = true;
      getTotalStatisticalList(data)
        .then((res) => {
          if (res.code === 1) {
            if (res.data.list.length !== 0) {
              this.detailedLastList = res.data.list.data.slice(-1);
              this.detailedList = res.data.list.data.filter(
                (item) => item.order_number !== "当前页合计"
              );
              this.detailedHead = res.data.list.header;
            } else {
              this.detailedList = [];
            }
            this.tableLoading = false;
            this.detaildedTotal = res.data.count;
          } else {
            this.tableLoading = false;
          }
        })
        .catch((res) => {
          this.tableLoading = false;
        });
    },
    handleCurrentChange(val) {
      this.detaildedForm.page = val;
      this.getTotalStatisticalList();
    },
    handleSizeChange(val) {
      this.detaildedForm.page_size = val;
      this.getTotalStatisticalList();
    },
    handleDetailGoodsType(type) {
      this.detaildedType = type;
      this.detaildedForm = {
        page: 1,
        page_size: 50,
      };
      this.getTypeList();
      this.getTotalStatisticalList();
    },
    /*
     *  导出判断
     */
    handleExport() {
      if (this.detaildedTotal > 3000) {
        const pageNum = Math.ceil(this.detaildedTotal / 3000);
        for (let i = 1; i <= pageNum; i++) {
          const info = {
            pageSize: 3000,
            page: i,
            text: 3000 * (i - 1) + 1 + "~" + 3000 * i + "条",
          };
          this.exportList.push(info);
        }
        this.exportVisiable = true;
      } else {
        this.handleExportDetail(1);
      }
    },
    /*
     *  关闭下载弹窗
     */
    cancelExport() {
      this.exportVisiable = false;
      this.exportList = [];
    },
    /*
     * 导出
     */
    handleExportDetail(type, item, index) {
      const data = {
        sales_statistical_id: this.sales_statistical_id,
        goods_type: this.detaildedType || "",
        fixed: {},
        custom: {},
      };
      if (type === 1) {
        if (this.detaildedForm.page) {
          data.page = this.detaildedForm.page;
        }
        if (this.detaildedForm.page_size) {
          data.page_size = 3000;
        }
      } else {
        this.exportIndex = index + 1;
        this.exportLoading = true;
        this.exportDisable = true;
        data.page = item.page;
        data.page_size = item.pageSize;
      }
      if (this.form.time_type) {
        data.time_type = this.form.time_type;
      }
      if (this.cond.day_time) {
        data.day_time =
          this.cond.day_time[0] / 1000 + "," + this.cond.day_time[1] / 1000;
      }
      if (this.cond.hierarchy_id) {
        data.hierarchy_id = this.cond.hierarchy_id;
      }

      if (this.detaildedForm.goods_type_id) {
        data.fixed.goods_type_id = this.detaildedForm.goods_type_id;
      }
      if (this.detaildedForm.supplier_id) {
        data.fixed.supplier_id = this.detaildedForm.supplier_id;
      }
      if (this.detaildedForm.warehouse_id) {
        data.fixed.warehouse_id = this.detaildedForm.warehouse_id;
      }
      if (this.goodsStyleKey !== 0 && this.detaildedForm[this.goodsStyleKey]) {
        data.custom[this.goodsStyleKey] =
          this.detaildedForm[this.goodsStyleKey];
      }
      if (this.goodsBrandKey !== 0 && this.detaildedForm[this.goodsBrandKey]) {
        data.custom[this.goodsBrandKey] =
          this.detaildedForm[this.goodsBrandKey];
      }
      if (this.materialKey !== 0 && this.detaildedForm[this.materialKey]) {
        data.custom[this.materialKey] = this.detaildedForm[this.materialKey];
      }

      data.fixed = JSON.stringify(data.fixed);
      data.custom = JSON.stringify(data.custom);
      downloadList(data).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        if (type === 2) {
          a.setAttribute("download", `销售统计明细` + item.text + `.xlsx`);
        } else {
          a.setAttribute("download", `销售统计明细.xlsx`);
        }

        a.href = URL.createObjectURL(blob);
        a.click();
        this.exportLoading = false;
        this.exportDisable = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  /deep/ .el-form-item {
    margin-bottom: 00px;
  }
}
.condition {
  line-height: 58px;
  padding-left: 21px;
  .condition_box {
    margin-left: 15px;
    padding: 8px 10px;
    border: 1px solid #c4cfdf;
    border-radius: 7px;
    font-size: 12px;
    color: #909399;
  }
}
/deep/ .el-form-item {
  margin-bottom: 5px !important;
}
.cline {
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
}
.conditionData {
  margin-left: 15px;
  margin-bottom: 15px;
  display: flex;
  padding: 11px 13px;
  max-width: 90%;
  overflow: auto;
  .condBox {
    padding: 15px 40px 15px 40px;
    opacity: 1;
    background: #f8fbfe;
    border-radius: 4px;
    text-align: center;
    white-space: nowrap;

    .num {
      font-size: 22px;
    }
    .text {
      color: #909399;
    }
  }
}
.trabsTitle {
  display: flex;
  padding-left: 18px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  .trabsSpan {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
  .title {
    display: flex;
    flex-direction: row;
    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
}
.tab {
  display: flex;
  padding: 0 18px;
  margin: 0 18px;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0px 0px;
  line-height: 58px;

  .tabBox {
    position: relative;
    padding: 0 30px;

    .label {
      position: absolute;
      bottom: 0;
      left: 41%;
      width: 18px;
      height: 4px;
      opacity: 1;
      background: #2791ff;
      border-radius: 2px;
    }
  }
  .tabBox:hover {
    cursor: pointer;
  }
}

.containData {
  position: relative;
  margin: 0 18px;
  border: 1px solid #e8e8e8;
  border-top: none;
  padding: 15px 20px;
}
.moneyContent {
  display: flex;
  padding-left: 21px;
  padding-bottom: 10px;
  .moneyTitle {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 59px;

    .span {
      margin: auto 10px auto 0;
      width: 4px;
      height: 16px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .operation {
    width: 48px;
    text-align: center;
    line-height: 88px;
    font-size: 22px;
    font-weight: 600;
  }
  .moneyBox {
    padding-top: 20px;
    width: 170px;
    height: 68px;
    opacity: 1;
    background: #f8fbfe;
    border-radius: 4px;
    text-align: center;

    .num {
      font-size: 22px;
    }
    .text {
      color: #909399;
    }
  }
}
</style>