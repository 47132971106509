import { post } from '@/utils/request';


// 销售统计-获取销售统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.sales/getStatistical',
    data: data
});

// 销售统计-编辑销售统计表
const editStatistical = (data) => post({
    url: '/store/statistical.sales/editStatistical',
    data: data
})


// 销售统计-新增销售统计表
const addStatistical = (data) => post({
    url: '/store/statistical.sales/addStatistical',
    data: data
})

// 销售统计-获取销售统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.sales/getStatisticalList',
    data: data
})

// 销售统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.sales/totalStatisticalData',
    data: data
})

// 销售统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.sales/totalStatisticalRadar',
    data: data
})

// 销售统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.sales/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.sales/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});

// 导出
const downloadList = (data) => post({
    url: '/store/statistical.sales/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.sales/deleteStatistical',
    data: data
})

// 支付渠道的统计
const totalStatisticalPay = (data) => post({
    url: '/store/statistical.sales/totalStatisticalPay',
    data: data
})

//优惠金额的统计
const totalStatisticalDiscount = (data) => post({
    url: '/store/statistical.sales/totalStatisticalDiscount',
    data: data
})

// 数据分析
const totalStatisticalData2D = (data) => post({
    url: '/store/statistical.sales/totalStatisticalData2D',
    data: data
})

// 数据分析-导出
const totalStatisticalData2DDownload = (data) => post({
    url: '/store/statistical.sales/totalStatisticalData2DDownload',
    responseType: 'blob',
    data: data
})

export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    downloadList,
    deleteStatistical,
    getAttrValueList,
    getMaterialListReq,
    totalStatisticalPay,
    totalStatisticalDiscount,
    totalStatisticalData2D,
    totalStatisticalData2DDownload
}
